import React from "react";
import './legends.css';
import { Row, Col } from 'react-bootstrap';



function Legends() {
    

    return (
        <Row className="legends-item-container">
            {/* <Col className="legends-item justify-content-center">
                <span className="legend-dot-note-text">Note:</span>
                <div className={"legend-dot-green"}></div><label className="legend-dot-text">Qualified</label>
                <div className={"legend-dot-red"}/><span className="legend-dot-text">Non-Qualified</span>
                <div className={"legend-dot-orange"} /><label className="legend-dot-text">Suspended</label>
            </Col> */}
        </Row>
    );
}

export default Legends;